<template>
  <div>
    <Modal
      :value="value"
      :title="`视频设备【${currentVideoObj.name}】的抓拍策略配置`"
      @input="(val) => $emit('input', val)"
      :width="1000"
      footer-hide
      @on-visible-change="modalShow"
      class="capture-modal"
    >
      <x-table
        new-style
        :columns="table.columns"
        :data="table.data"
        :loading="table.loading"
        size="small"
      >
        <template #header>
          <div class="table-header">
            <div class="search-pane">
              <span>
                策略周期：
                <Select
                  v-model="search_data.cycle"
                  clearable
                  style="width: 200px"
                >
                  <Option :value="1">今天</Option>
                  <Option :value="2">每天</Option>
                  <Option :value="3">每周</Option>
                  <Option :value="4">每月</Option>
                  <Option :value="5">输入时间间隔</Option>
                </Select>
              </span>
              <span>
                <Button type="primary" icon="md-search" @click="search"
                  >查询</Button
                >
              </span>
            </div>
            <div>
              <Button type="primary" icon="ios-add" @click="add"
                >添加策略</Button
              >
            </div>
          </div>
        </template>
        <template #footer>
          <div class="footer">
            <Page
              :current="page.pageNo"
              :page-size="page.pageSize"
              :total="page.total"
              size="small"
              @on-change="pageChange"
            ></Page>
          </div>
        </template>
      </x-table>
    </Modal>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="captureModalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="策略周期" prop="captureCycle">
          <Select v-model="form.captureCycle" clearable style="width: 300px">
            <Option value="1/今天">今天</Option>
            <Option value="2/每天">每天</Option>
            <Option value="3/每周">每周</Option>
            <Option value="4/每月">每月</Option>
            <Option value="5/输入时间间隔">输入时间间隔</Option>
          </Select>
        </FormItem>
        <FormItem
          v-if="form.captureCycle && form.captureCycle.split('/') == 5"
          prop="cycleVal"
        >
          <Input v-model="form.cycleVal" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="执行日期" prop="executive">
          <DatePicker
            v-model="form.executive"
            type="daterange"
            style="width: 300px"
          ></DatePicker>
        </FormItem>
        <FormItem label="策略时段" prop="time">
          <TimePicker
            v-model="form.time"
            confirm
            type="timerange"
            style="width: 300px"
            format="HH:mm"
            @on-ok="timeSlotAdd"
          ></TimePicker>
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              width: 300px;
              margin-top: 10px;
            "
          >
            <Tag
              v-for="(item, index) in timeList"
              :key="index"
              :name="index"
              closable
              @on-close="handleClose"
              >{{ item.beginTime }}~{{ item.endTime }}</Tag
            >
          </div>
        </FormItem>
        <FormItem label="备注" prop="remark">
          <Input
            v-model="form.remark"
            type="textarea"
            :row="3"
            style="width: 300px"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import { Switch } from "view-design";
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currentVideoObj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      table: {
        columns: [
          {
            title: "策略周期",
            width: 120,
            key: "cycleName",
          },
          {
            title: "执行日期",
            minWidth: 100,
            render: (h, { row }) => {
              return (
                <span>{`${row.executiveTime}~${row.executiveEndTime}`}</span>
              );
            },
          },
          {
            title: "策略时段",
            minWidth: 100,
            render: (h, { row }) => {
              return (
                <div style="display:flex;flex-wrap:wrap;">
                  {JSON.parse(row.timelist).map((item) => {
                    return <Tag>{`${item.beginTime}~${item.endTime}`}</Tag>;
                  })}
                </div>
              );
            },
          },
          {
            title: "备注",
            width: 100,
            key: "remark",
          },
          {
            title: "状态",
            width: 100,
            render: (h, { row }) => {
              return (
                <Switch
                  size="large"
                  true-value={1}
                  false-value={
                    +row.status === 0 ? 0 : +row.status === 2 ? 2 : ""
                  }
                  loading={this.statusLoading}
                  value={+row.status}
                  onInput={(val) => this.statusChange(val, row.id)}
                >
                  <span slot="open">启用</span>
                  <span slot="close">停用</span>
                </Switch>
              );
            },
          },
          {
            title: "操作",
            width: 100,
            render: (h, { row }) => {
              return (
                <div>
                  <a
                    class="iconfont"
                    title="编辑"
                    style="margin-right: 10px"
                    onClick={() => this.edit(row)}
                  >
                    &#xe669;
                  </a>
                  <Poptip
                    confirm
                    transfer
                    title="确定删除吗?"
                    on-on-ok={() => this.del(row.id, row.guid)}
                  >
                    <a class="iconfont" title="删除">
                      &#xe66a;
                    </a>
                  </Poptip>
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
      },
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      statusLoading: false,
      form: {
        captureCycle: "",
        executive: [],
        time: [],
        remark: "",
        cycleVal: "",
      },
      rules: {
        captureCycle: [{ required: true, message: "请选择策略周期" }],
        executive: [
          { required: true, type: "array", message: "请选择执行日期" },
        ],
        time: [{ required: true, type: "array", message: "请选择策略时段" }],
        cycleVal: [
          { required: true, message: "请填写策略周期间隔" },
          {
            validator(rule, value, callback) {
              if (/^[0-9]*$/.test(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请填写正确的周期间隔",
          },
        ],
      },

      timeList: [],
      search_data: {
        cycle: "",
      },
    };
  },
  methods: {
    search() {
      this.page.pageNo = 1
      this.getList()
    },
    add() {
      this.modal = {
        show: true,
        title: "添加策略",
        submitLoading: false,
      };
    },
    pageChange(pageNO) {
      this.page.pageNo = pageNO;
      this.getList();
    },
    getList() {
      this.table.loading = true;
      this.$post(this.$api.RECORD.RECORD_PHOTO_PLOY_LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    timeSlotAdd() {
      let obj = {
        beginTime: this.form.time[0],
        endTime: this.form.time[1],
      };
      this.timeList.push(obj);
      // this.form.time = [];
    },
    handleClose(event, name) {
      this.timeList.splice(name, 1);
    },
    statusChange(status, id) {
      this.statusLoading = true;
      this.$post(this.$api.RECORD.RECORD_PHOTO_PLOY_STATUS_CHANGE, {
        status,
        id,
      })
        .then(() => {
          this.$Message.success(status === 1 ? "启用成功" : "停用成功");
          this.getList();
        })
        .finally(() => {
          this.statusLoading = false;
        });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.guid = row.guid;
      this.form.captureCycle = `${row.cycle}/${row.cycleName}`;
      this.form.executive = `${row.executiveTime},${row.executiveEndTime}`.split(
        ","
      );
      this.timeList = JSON.parse(row.timelist);
      this.form.time = `${this.timeList[this.timeList.length - 1].beginTime},${
        this.timeList[this.timeList.length - 1].endTime
      }`.split(",");
      this.modal = {
        show: true,
        title: "编辑视频抓拍策略",
        submitLoading: false,
      };
    },
    del(id, guid) {
      this.$post(this.$api.BUSSDEVICESENSOR.RECORD_PHOTO_PLOY_DEL, {
        id,
        guid,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    captureModalShow(visible) {
      if (visible) return;
      this.form = {
        captureCycle: "",
        executive: [],
        time: [],
        remark: "",
        cycleVal: "",
      };
      this.$refs.form.resetFields();
      this.timeList = [];
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.executiveTime = moment(this.form.executive[0]).format(
          "YYYY-MM-DD"
        );
        params.executiveEndTime = moment(this.form.executive[1]).format(
          "YYYY-MM-DD"
        );
        params.farmId = this.currentVideoObj.farmId;
        params.sceneId = this.currentVideoObj.sceneId;
        params.recordVideoId = this.currentVideoObj.id;
        params.timelist = JSON.stringify(this.timeList);
        params.cycle = this.form.captureCycle.split("/")[0];
        params.cycleName = this.form.captureCycle.split("/")[1];
        delete params.captureCycle;
        delete params.executive;
        delete params.time;
        this.modal.submitLoading = true;
        this.$post(
          params.guid
            ? this.$api.BUSSDEVICEVIDEO.UPDATE
            : this.$api.BUSSDEVICEVIDEO.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
    modalShow(visible) {
      if (visible) {
        this.getList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.capture-modal {
  .table-header {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 16px;
    .search-pane {
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 1;
      flex-grow: 1;
      span {
        padding-right: 16px;
      }
    }
  }
  .footer {
    text-align: right;
    padding-top: 16px;
  }
}
</style>